<template>
  <div class="page">
    <h2>人脸识别服务协议</h2>
    <p>欢迎您使用防疫卫士人脸识别服务。</p>
    <p>请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款。限制、免责条款可能以加粗形式提示您注意。</p>
    <p>1.协议的范围</p>
    <p>
      1.1.
      本协议是您与江苏富敏人工智能科技有限公司（以下简称“本公司”）之间关于您使用防疫卫士人脸识别服务所订立的有效协议。
    </p>
    <p>
      1.2.本协议内容同时包括本公司可能不断发布的关于本服务的相关协议、业务规则等内容。上述内容一经正式发布，即为本协议不可分割的组成部分，您同样应当遵守。
    </p>
    <p>
      1.3.处理此类信息的目的仅在于操作应用程序的人脸识别功能（包括但不限于衰老效果、性别转化效果、人脸融合效果、换脸效果、滤镜效果），相机和照片。为了使用该应用程序的某些功能，您可以自愿授予我们访问相机或相册的权限，以便在您的移动设备中获取照片。我们可能会通过使用Photo Kit API来收集您在使用该应用程序时上传或提交的照片，图片和其他数据。此信息对于您与我们之间的合同的适当履行是必要的。
    </p>
    <p>2.保护个人信息</p>
    <p>
      2.1.我们采取预防措施，包括行政，技术和物理措施，以保护您的个人信息免遭丢失，盗窃和滥用以及未经授权的访问，披露，更改和破坏。
    </p>
    <p>
      2.2.确保您的个人信息安全；我们会向所有员工传达我们的隐私和安全准则，并严格执行公司内部的隐私保护措施。
    </p>
    <p>
      2.3.不幸的是，互联网上的传输方法或电子存储方法都不是100％安全的。我们尽力保护您的个人信息，但是，我们不能保证其绝对安全。
    </p>
    <p>3.第三方内容</p>
    <p>
      3.1.某些软件和服务可能包括和/或使您能够向其中添加内容。内容的形式可以是按钮，小工具，指向第三方广告，网站，产品和服务（包括搜索服务）的链接，以及第三方提供给您的其他方式（统称为“第三方内容和服务” ”）。此类第三方内容和服务的使用受其隐私政策的约束。您应了解，使用第三方内容和服务可能使这些第三方能够访问，收集，存储和/或共享您的信息，包括您的PII和非个人身份信息。为澄清起见，我们不对此类第三方内容和服务进行审查，批准，监控，认可，保证或作出任何陈述，并且您访问任何第三方内容和服务的风险由您自行承担。对于这些第三方内容和服务的实践，您的使用或无法使用或无法使用这些信息，我们不承担任何责任。您明确免除我们因使用此类第三方内容和服务而引起的任何责任。我们建议您在使用或访问任何第三方内容和服务之前，请阅读其条款，条件和隐私策。
    </p>
    <p>4.隐私问题</p>
    <p>4.1.在本协议中未声明的其他一切权利，仍归本公司所有。本公司保留对本协议的最终解释权利。</p>
  </div>
</template>

<script>
export default {
  name: "register",
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.page{
    width: 100%;
    line-height: .625rem;
    h2{
        margin: .5rem 0;
        text-align: center;
    }
    p{
        margin:auto .5rem
    }
}
</style>








































