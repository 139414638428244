<template>
  <div v-if="!isWeiXinWeb" class="page_title">
    <span>{{title}}</span>
  </div>
</template>

<script>
import { isWeiXin } from "../Utils/utils";
export default {
  name: "pageTitle",
  props: ['title'],
   created() {
    this.isWeiXinWeb = isWeiXin();
  },
  data(){
      return {
            isWeiXinWeb: "",
      }
  }
};
</script>

<style scoped>
.page_title {
  height: 1.5625rem;
  background-color: #2a82e4;
  /* background-color: coral; */
  color: white;
  font-size: 0.5rem;
  text-align: center;
  line-height: 1.5625rem;
  border: none;
}
</style>