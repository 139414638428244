import { baseUrl } from "./manage";

const getWeiXinConfig = `${baseUrl}maymuse/mmVisitors/list`;
const login = `${baseUrl}/h5/login`;
const upload = `${baseUrl}/sys/common/uploadFace`;
const homeApi = `${baseUrl}/h5/home`;
const visitorsList = `${baseUrl}/h5/visitorsList`
const refreshUser = `${baseUrl}/h5/user/refresh`;
const userEdit = `${baseUrl}/h5/user/edit`;
const visitorsAdd = `${baseUrl}/h5/visitorsAdd`;
const visitorsAutoAdd = `${baseUrl}/h5/visitorsAutoAdd`;
const deviceList = `${baseUrl}/h5/accessPermissionsDeviceList`;
export {
    getWeiXinConfig,
    login,
    upload,
    homeApi,
    visitorsList,
    refreshUser,
    userEdit,
    visitorsAdd,
    visitorsAutoAdd,
    deviceList
}