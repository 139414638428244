<template>
  <div class="page">
    <h2>会员注册服务协议</h2>
    <p>欢迎申请使用防疫卫士。请您重点阅读。</p>
    <p>
      若您点击登录即表示同意本协议，表示您已充分阅读、理解并接受本协议的全部内容。
    </p>
    <p>1.注册与使用</p>
    <p>
      1.1.
      您确认，您已具备中华人民共和国法律规定的与您行为相适应的民事行为能力。若您不具备前述民事行为能力，则您及您的监护人应依照法律规定承担因此导致的一切后果。
    </p>
    <p>
      1.2.您按照注册页面提示填写信息、设置密码、阅读并同意本协议并完成全部注册程序后或以其他允许的方式实际使用防疫卫士服务时，用户即成为会员，以下称“用户”或“您”。
    </p>
    <p>
      1.3.您进行注册时，必须填写准确、真实、有效的个人资料，倘若您提供任何不真实、不准确、不完整或不能反映当前情况的资料，或防疫卫士有合理理由怀疑前述资料不真实、不准确、不完整或不能反映当前情况，防疫卫士有权暂停或终止您的注册服务。
    </p>
    <p>
      1.4.您在登录后必须遵循以下规定，否则防疫卫士有权做出独立判断，立即取消您的会员资格并注销您的账号：
    </p>
    <p>（1）遵守国家法律法规、当地法规及互联网相关规定；</p>
    <p>（2）遵守所有服务有关的协议、规定、程序以及防疫卫士的服务条款；</p>
    <p>
      （3）不得利用防疫卫士的服务系统进行任何可能对互联网或移动网络正常运转造成不利影响的行为；
    </p>
    <p>
      （4）不得利用防疫卫士提供的服务上传、展示或传播任何虚假的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料；
    </p>
    <p>（5）不得通过盗取账号、密码，诈骗等不正当手段获得会员信息。</p>
    <p>1.5.信息管理</p>
    <p>
      1.5.1.在登录防疫卫士相关的网站、系统、APP或者第三方程序时，您应当按照系统页面的提示准确完整地提供您的信息。如前述信息发生变更，您应当及时提供最新、真实、完整的信息，以免影响账户的正常使用。
    </p>
    <p>
      1.5.2.您了解并同意，您有义务保持您提供信息的真实性及有效性，如您提供的信息不实或失效的，您应承担因此给您自身、他人及防疫卫士造成的全部损失与不利后果。
    </p>
    <p>1.6.账户安全</p>
    <p>
      1.6.1.您的账户（包括账户名、密码等，下同）由您自行设置并由您保管，防疫卫士在任何时候均不会主动要求您提供账户，请您务必妥善保管账户。因您主动泄露、保管不当而泄露或遭受他人攻击、诈骗等行为导致的损失及后果，防疫卫士不承担任何责任。
    </p>
    <p>
      1.6.2.如发现任何未经授权使用您账户或其他可能导致您的账户遭窃、遗失的情况，建议您立即通知防疫卫士，并授权防疫卫士将该信息同步给防疫卫士的关联公司或合作方。您理解防疫卫士对您的任何请求采取行动均需要合理时间，除防疫卫士存在过错外，防疫卫士对在采取行动前已经产生的后果不承担任何责任。
    </p>
    <p>2.服务内容</p>
    <p>
      2.1.您可以在登录后使用以下服务包括但不限于自主录入访客信息、管理您本人信息、邀请访客自助录入其信息、查看可通行设备列表、审核访客权限、查看访客数据，上述服务可能因防疫卫士进行业务调整而被增加或修改，或因定期、不定期的维护而暂缓提供，您可以登录防疫卫士系统查看具体的信息。
    </p>
    <p>3.争议处理</p>
    <p>
      3.1.您在使用防疫卫士系统过程中与其他用户发生争议的，您与争议相对方应自行通过协商、调解、仲裁、诉讼等途径依法解决。
    </p>
    <p>4.免责声明</p>
    <p>4.1.您明确同意承担使用防疫卫士服务所存在的一切后果</p>
    <p>
      4.2.由于您自己将会员密码告知他人、与他人共享注册账户、转借或出让账户给他人使用，由此导致任何个人信息的泄露等由您自行承担，防疫卫士无需承担任何责任。
    </p>
    <p>
      4.3.防疫卫士有可能会提供其他网站的链接，这些链接的网站并非由防疫卫士控制，故不对该网站的内容、其链接网站的内容及其内容的更新及转变承担任何责任，这些网站的使用及内容也不代表防疫卫士的立场。
    </p>
    <p>
      4.4.防疫卫士有义务在技术上保证防疫卫士的正常运行，而对于因不可抗力或防疫卫士不能控制的原因造成的服务中断或其它缺陷，防疫卫士不承担任何责任，但防疫卫士会尽力协助处理以减少因此给用户造成的损失和影响。
    </p>
    <p>5.用户信息保护、授权及使用</p>
    <p>
      5.1.个人信息的保护，防疫卫士非常重视用户个人信息的保护，在您使用防疫卫士服务时，您同意防疫卫士按照相关法律法规之规定、本协议约定以及公布在防疫卫士官网的隐私政策收集、存储、使用、披露和保护您的个人信息。
    </p>
    <p>6.协议终止</p>
    <p>
      6.1.通知解除：协议任何一方均可以提前十五（15）日书面通知的方式解除本协议。
    </p>
    <p>
      6.2.防疫卫士单方解除权：如您违反本协议中的任何约定、承诺、保证或任何平台规则，防疫卫士都有权立刻解除本协议，并按照本协议约定或平台规则规定对您进行违约处理。
    </p>
    <p>
      6.3.如您连续满九十（90）天未登录防疫卫士系统的，防疫卫士有权解除本协议。
    </p>
    <p>6.4.本协议约定的其他终止条件发生或实现，导致本协议终止。</p>
    <p>7.通知</p>
    <p>
      7.1.本系统将通过网页公告或电子邮件或手机短信传送等方式，把通告传送给用户，该等通告于发送之日即视为已送达收件人。
    </p>
    <p>8.法律适用与管辖</p>
    <p>
      8.1.本协议的订立、履行、解释、变更及争议解决均适用中华人民共和国法律（为避免歧义，不包含冲突法规则及港、澳、台地区法律）。
    </p>
  </div>
</template>

<script>
export default {
  name: "register",
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.page{
    width: 100%;
    line-height: .625rem;
    h2{
        margin: .5rem 0;
        text-align: center;
    }
    p{
        margin:auto .5rem
    }
}
</style>








































