<template>
  <div class="page_content">
    <PageTitle title="富敏人证核验平台" />
    <div class="page_top">
      <div @click="toEntry" class="userInfo">
        <van-image
          round
          fit="cover"
          width="1.5625rem"
          height="1.5625rem"
          :src="photo"
        >
          <template v-slot:error>
            <van-icon size='100'  name="manager" />
          </template>
        </van-image>
        <div style="margin-left: 0.3rem; display: inline-flex">
          <div class="cell userNameStyel">{{ realName }}</div>
          {{ greetings }}！
        </div>
      </div>
    </div>

    <div class="page_menu">
      <div class="menu_item" style="background-color: rgba(42, 130, 228, 1)">
        <div class="menu_item_top">
          <van-icon class="iconfont" class-prefix="icon" name="zhaji" />
          <span>我的权限</span>
        </div>
        <div class="menu_item_middle">{{ deviceNum }} 台</div>
        <div class="menu_item_foot_box">
          <van-button
            class="menu_item_foot"
            @click="toPersonalDetails"
            round
            size="normal"
            type="default"
            >查看</van-button
          >
        </div>
      </div>

      <div class="menu_item" style="background-color: rgba(255, 141, 26, 1)">
        <div class="menu_item_top">
          <van-icon class="iconfont" class-prefix="icon" name="zuji" />
          <span>今日访客</span>
        </div>
        <div class="menu_item_middle">
          <div
            class="cell visitorStyle"
            v-for="(item, i) in visitorsList.slice(0, 2)"
            :key="i"
          >
            {{ item }}
          </div>
        </div>
        <div class="menu_item_foot_box">
          <van-button
            @click="toVisitorsList"
            class="menu_item_foot"
            round
            size="normal"
            type="default"
            >更多</van-button
          >
        </div>
      </div>

      <div class="menu_item" style="background-color: rgba(0, 186, 173, 1)">
        <div class="menu_item_top">
          <van-icon class="iconfont" class-prefix="icon" name="gerenxinxi" />
          <span>信息维护</span>
        </div>
        <div class="menu_item_middle">证件、照片信息维护</div>
        <div class="menu_item_foot_box">
          <van-button
            class="menu_item_foot"
            round
            size="normal"
            @click="toEntry"
            type="default"
            >操作</van-button
          >
        </div>
      </div>

      <div class="menu_item" style="background-color: rgba(241, 133, 110, 1)">
        <div class="menu_item_top">
          <van-icon class="iconfont" class-prefix="icon" name="fangkerenshu" />
          <span>访客审核</span>
        </div>
        <div class="menu_item_middle">管理员功能</div>
        <div class="menu_item_foot_box">
          <van-button
            class="menu_item_foot"
            @click="toCheck"
            round
            size="normal"
            type="default"
            >操作</van-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isWeiXin, timeFix } from "../../Utils/utils";
import PageTitle from "../../components/pageTitle";
import { Notify } from "vant";
import { homeApi, refreshUser } from "../../api/api";
import { GET_Query } from "../../Utils/request";
import { imageBaseUrl } from "../../api/manage";
import { Dialog } from "vant";
export default {
  name: "Home",
  props: {
    msg: String,
  },
  components: {
    PageTitle,
  },
  data() {
    return {
      isWeiXinWeb: "",
      greetings: "",
      deviceNum: 0,
      photo: "",
      visitorsList: [],
      realName: "嗨",
      userId: "",
    };
  },
  created() {
    const userId = localStorage.getItem("userId");
    this.userId = userId;
    this.isWeiXinWeb = isWeiXin();
    this.greetings = timeFix();
    GET_Query(homeApi, { userId: userId }).then((res) => {
      if (res.code === 200) {
        this.deviceNum = res.result.accessPermissionsDeviceNum;
        this.visitorsList = res.result.visitorsList;
      } else {
        Notify({ type: "danger", message: res.message });
      }
    });

    GET_Query(refreshUser, { userId: userId }).then((res) => {
      if (res.code === 200) {
        if (!res.result.photo) {
          Dialog.alert({
            title: "完善信息",
            message: "请完善信息！",
            confirmButtonText: "立即前往",
          }).then(() => {
            this.toEntry();
          });
          return false;
        }
        if (res.result.realName) {
          this.realName = res.result.realName;
          this.photo = `${imageBaseUrl}${res.result.photo}`;
          localStorage.setItem("userName", res.result.realName);
          localStorage.setItem("userMobile", res.result.mobile);
        }
      } else {
        Notify({ type: "danger", message: res.message });
      }
    });
  },
  activated() {},
  watch: {},
  methods: {
    toEntry() {
      this.$router.push({
        name: `entry`,
        query: {
          userId: this.userId,
        },
      });
    },
    toVisitorsList() {
      this.$router.push({ path: "visitorsList" });
    },
    toPersonalDetails() {
      this.$router.push({ path: "permissions" });
    },
    toCheck() {
      Notify({ type: "warning", message: "此功能正在开发中，暂未对外开放！" });
    },
  },
};
</script>

<style scoped>
.page_content {
  min-height: 100%;
  background-color: rgb(236, 234, 234);
}
.page_top {
  height: 2.5625rem;
  text-align: center;
  margin-bottom: 0.5rem;
  background-color: rgba(42, 130, 228, 1);
  border: none;
  position: relative;
}
.treaty {
  text-align: center;
}
.userInfo {
  width: 80%;
  background-color: white;
  height: 1.875rem;
  line-height: 1.875rem;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  border-radius: 20px;
  font-size: 0.625rem;
  position: absolute;
  bottom: -0.9375rem;
  left: 50%;
  transform: translate(-50%);
  -webkit-box-shadow: 0px 3px 3px #c8c8c8;
  -moz-box-shadow: 0px 3px 3px #c8c8c8;
  box-shadow: 0px 3px 3px #c8c8c8;
}
.page_menu {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0.5rem;
  margin-top: 1.25rem;
}
.menu_item {
  height: 5rem;
  width: 4rem;
  border-radius: 0.625rem;
  margin-bottom: 0.5rem;
  color: #fff;
}
.menu_item_top {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  font-size: 0.5rem;
  align-items: center;
}
.visitorStyle {
  width: 3.75rem;
}
.userNameStyel {
  width: 2.5rem;
}
.iconfont {
  font-size: 0.625rem;
}
.menu_item_middle {
  height: 30%;
  text-align: center;
  font-size: 0.375rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.menu_item_foot_box {
  display: flex;
  justify-content: center;
}
.menu_item_foot {
  width: 60%;
}
</style>
