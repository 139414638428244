<template>
  <div id="app">
    <!-- <transition :name="transitionName" mode="out-in"> -->
    <router-view class="Router" />
    <!-- </transition> -->
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      transitionName: "",
    };
  },
  watch: {
    $route(to, from) {
      if (from.meta.index == undefined) {
        from.meta.index = "0";
      }
      if (to.meta.index > from.meta.index) {
        this.transitionName = "slide-left";
      } else {
        this.transitionName = "slide-right";
      }
    },
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
html,
body,
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
.Router {
  position: absolute;
  width: 100%;
  transition: all 0.5s ease;
  top: 0;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  -webkit-transform: translate(100%, 0);
  transform: translate(100%, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  -webkit-transform: translate(-100%, 0);
  transform: translate(-100% 0);
}

.cell {
  overflow: hidden;

  white-space: nowrap;

  text-overflow: ellipsis;

  -o-text-overflow: ellipsis;
}
</style>
