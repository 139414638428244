<template>
  <div class="page_content">
    <PageTitle title="我的访客" />
    <div class="listBox">
      <div class="van-list-box">
        <van-list
          v-if="!noData"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <!-- <van-cell v-for="item in list" :key="item" :title="item" /> -->
          <van-cell
            center
            v-for="item in list"
            :key="item.id"
            :title="item.realName"
          >
            <template #title>
              <van-image
                round
                fit="cover"
                width=".75rem"
                height=".75rem"
                :src="imageBaseUrl + item.photo"
              >
                <template v-slot:error>
                  <van-icon size="100" name="manager" />
                </template>
              </van-image>
              <span class="cell">{{ item.realName }}</span>

              <span
                class="checkStatus"
                :style="{ color: statusColor[item.status] }"
                >{{ item.status_dictText }}</span
              >

              <span>{{ item.startTime }}</span>
            </template>
          </van-cell>
        </van-list>
        <div v-else>
          <van-empty description="暂无数据！" />
        </div>
      </div>
    </div>

    <div class="footer_box">
      <van-button
        type="info"
        @click="toPersonalDetails"
        class="visitor_btn"
        block
        >访客信息录入</van-button
      >

      <van-button
        type="info"
        @click="toVisitorsSelfHelp"
        class="invitation_btn"
        block
        >邀请访客录入</van-button
      >
    </div>
    <van-popup
      v-model="showQrcode"
      closeable
      close-icon="close"
      @close="cleanCode"
      get-container="#app"
    >
      <div class="qrcodeBox">
        <div>可长按二维码或将其截图保存并发送给访客进行自助录入</div>
        <div id="qrcode" class="qrcode" ref="qrcode"></div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { isWeiXin } from "../../Utils/utils";
import PageTitle from "../../components/pageTitle";
import { visitorsList } from "../../api/api";
import { GET } from "../../Utils/request";
import { imageBaseUrl } from "../../api/manage";
import QRCode from "qrcodejs2";
import { Notify } from "vant";
export default {
  name: "VisitorsList",
  props: {
    msg: String,
  },
  components: {
    PageTitle,
  },
  data() {
    return {
      isWeiXinWeb: "",
      list: [],
      imageBaseUrl: imageBaseUrl,
      noData: false,
      loading: false,
      finished: false,
      pageNo: 1,
      pageSize: 10,
      showQrcode: false,
      userId: "",
      userName: "",
      userMobile: "",
      statusColor: ["#ff9900", "#ed3f14", "#19be6b", "#19be6b", "#cccccc"],
    };
  },
  created() {
    this.isWeiXinWeb = isWeiXin();
  },
  watch: {},
  methods: {
    getVisitorsList() {
      this.userId = localStorage.getItem("userId");
      this.userName = localStorage.getItem("userName");
      this.userMobile = localStorage.getItem("userMobile");
      GET(visitorsList, {
        visitUserId: this.userId,
        pageSize: 10,
        pageNo: this.pageNo,
      }).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          this.list = this.list.concat(res.result.records);
          this.pageNo++;
          if (this.list.length === 0) {
            this.noData = true;
          }
          if (this.list.length >= res.result.total) {
            this.finished = true;
          }
        } else {
          Notify({ type: "danger", message: res.message });
        }
      });
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        this.getVisitorsList();
        this.loading = true;
      }, 500);
    },
    toPersonalDetails() {
      this.$router.push({ path: "PersonalDetails" });
    },
    toVisitorsSelfHelp() {
      this.showQrcode = true;
      this.$nextTick(() => {
        // document.getElementById("qrcode").innerHTML = "";
        this.$nextTick(function () {
          //生成二维码
          this.qrcode(
            `${window.location.origin}/visitorsSelfHelp?userId=${
              this.userId
            }&&userName=${this.userName ? this.userName : this.userMobile}`
          );
        });
      });
    },

    qrcode(link) {
      // eslint-disable-next-line no-unused-vars
      let qrcode = new QRCode("qrcode", {
        width: 300,
        height: 300, // 高度
        text: link, // 二维码内容
        render: "table",
        background: "#f0f", // 背景色
        foreground: "#ff0", // 前景色
      });
    },
    cleanCode() {
      this.$refs.qrcode.innerHTML = "";
    },
  },
};
</script>

<style scoped lang="less" >
.page_content {
  height: 100vh;
  background-color: rgb(236, 234, 234);
  position: relative;
}
.listBox {
  // padding-top: 0.9375rem;
  height: 63%;
  overflow-y: scroll;
  padding-bottom: 0.5rem;
}
.van-list-box {
  height: 100%;
}
.van-list {
}
.van-cell__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 0.375rem;
}
.footer_box {
  padding: 0.5rem;
  position: absolute;
  bottom: 0.5rem;
  left: 0;
  right: 0;
}
.invitation_btn {
  margin-top: 0.5rem;
}
.visitor_btn,
.invitation_btn {
  border-radius: 0.3125rem;
}
.cell {
  width: 1.875rem;
}
.qrcodeBox {
  width: 6.25rem;
  height: 7.5rem;
  padding: 0.625rem;
  margin-top: 0.75rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.qrcode {
  margin-top: 0.5rem;
  display: inline-flex;
}

// 动态添加的元素常规方式无法更改样式时，需要使用vue 的样式穿透进行修改写法  选择器 /deep/ 选择器{ ...}
.qrcode /deep/ img {
  width: 5rem !important;
  height: 5rem !important;
  display: block !important;
}
.checkStatus {
  width: 120px;
}
</style>
