import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant'
import 'vant/lib/index.css'
import 'lib-flexible/flexible.js'
import router from './router/index'
import "./assets/iconfont/iconfont.css"
import axios from 'axios'
import {Toast} from 'vant';
// import VueCropper from 'vue-cropper' 

Vue.config.productionTip = false

Vue.use(Vant);

let loading;
let loadingNum = 0;
function startLoading(){
  if(loadingNum === 0){
    loading = Toast.loading({
      message: '加载中...',
      forbidClick: true,
    });
  }
  loadingNum++;
}

function endLoading(){
  loadingNum--
  if(loadingNum<=0){
    loading.clear();
  }
}

axios.interceptors.request.use(request =>{
  startLoading();
  return request
},err =>{
  return Promise.reject(err)
});

axios.interceptors.response.use(response =>{
  endLoading();
  return response
},err=>{
  endLoading();
  if(err && err.response){
    switch(err.response.status){
      case 400: err.message = '请求错误(400)'; break;
      case 401: this.$router.push('/'); break;
      case 403: err.message = '拒绝访问(403)'; break;
      case 404: err.message = '请求出错(404)'; break;
      case 408: err.message = '请求超时(408)'; break;
      case 500: err.message = '服务器错误(500)'; break;
      case 501: err.message = '服务未实现(501)'; break;
      case 502: err.message = '网络错误(502)'; break;
      case 503: err.message = '服务不可用(503)'; break;
      case 504: err.message = '网络超时(504)'; break;
      case 505: err.message = 'HTTP版本不受支持(505)'; break;
      default: err.message = `连接出错(${err.response.status})!`;
    }
  }else {
    err.message = "链接服务器失败！"
  }

  return Promise.reject(err);
})


new Vue({
  el: '#app', //新增，这里的app是在App.vue文件中定义的ID
  router, //新增
  render: h => h(App),
}).$mount('#app')
