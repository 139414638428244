<template>
  <div class="page_content">
    <PageTitle title="权限设备列表" />
    <van-list
      v-if="!noData"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-cell
        style="width: 100%"
        center
        v-for="(item, i) in list"
        :key="item.id"
      >
        <template #title>
          <span style="width: 0.98rem">{{ i + 1 }}</span>
          <span class="cell" style="width: 6.25rem">{{
            item.mmDevice.installAddress
          }}</span>
          <span class="cell" style="width: 1.5rem; text-align: left;">{{
            item.mmDevice.name
          }}</span>
        </template>
      </van-cell>
    </van-list>
    <div v-else>
      <van-empty description="暂无数据！" />
    </div>
  </div>
</template>

<script>
import { isWeiXin } from "../../Utils/utils";
import PageTitle from "../../components/pageTitle";
import { deviceList } from "../../api/api";
import { GET } from "../../Utils/request";
import { imageBaseUrl } from "../../api/manage";
import { Notify } from "vant";
export default {
  name: "Permissions",
  props: {
    msg: String,
  },
  components: {
    PageTitle,
  },
  data() {
    return {
      isWeiXinWeb: "",
      list: [],
      imageBaseUrl: imageBaseUrl,
      noData: false,
      loading: false,
      finished: false,
      pageNo: 1,
      pageSize: 10,
      userId: "",
    };
  },
  created() {
    this.isWeiXinWeb = isWeiXin();
  },
  watch: {},
  methods: {
    getVisitorsList() {
      this.userId = localStorage.getItem("userId");
      GET(deviceList, {
        userId: this.userId,
        pageSize: 10,
        pageNo: this.pageNo,
      }).then((res) => {
        if (res.code === 200) {
          this.loading = false;
          this.list = this.list.concat(res.result.records);
          this.pageNo++;
          if (this.list.length === 0) {
            this.noData = true;
          }
          if (this.list.length >= res.result.total) {
            this.finished = true;
          }
        } else {
          Notify({ type: "danger", message: res.message });
        }
      });
    },
    onLoad() {
      setTimeout(() => {
        this.getVisitorsList();
        this.loading = true;
      }, 500);
    },
  },
};
</script>

<style scoped>
.van-cell__title {
  display: flex;
  align-items: center;
  margin-right: 0.375rem;
}
.cell {
  overflow: hidden;
 
}
</style>
