import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../page/login/index';
import homePage from '../page/home/index';
import visitorsSelfHelp from '../page/visitorsSelfHelp/index'
import PersonalDetails from '../page/personalDetails/index'
import entry from '../page/entry/index'
import visitorsList from '../page/visitorsList/index'
import permissions from '../page/permissions/index'
import register from '../page/agreement/register'
import privacy from '../page/agreement/privacy'
import faceRecognition from '../page/agreement/faceRecognition'


Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',  //去掉url中的#
    routes: [
        {
            path: '/',
            name: 'login',
            meta:{title:'防疫卫士',index:0},
            component: Login
        },
        {
            path: '/home',
            name: 'home',
            meta:{title:'富敏人证核验平台',index:1,requiresAuth:true},
            component: homePage
        },
        {
            path:'/visitorsList',
            name:'visitorsList',
            meta:{title:'我的访客',index:2,requiresAuth:true},
            component:visitorsList

        },  {
            path: '/permissions',
            name: 'permissions',
            meta:{title:'权限设备列表',index:3,requiresAuth:true},
            component: permissions
        },
        {
            path: '/entry',
            name: 'entry',
            meta:{title:'信息录入',index:4,requiresAuth:true},
            component: entry
        },
        {
            path: '/PersonalDetails',
            name: 'PersonalDetails',
            meta:{title:'访客信息录入',index:5,requiresAuth:true},
            component: PersonalDetails
        },
        {
            path: '/visitorsSelfHelp',
            name: 'visitorsSelfHelp',
            meta:{title:'访客信息自助录入',index:6},
            component: visitorsSelfHelp
        },
        {
            path: '/register',
            name: 'register',
            meta:{title:'注册会员服务条款',index:7},
            component: register
        },
        {
            path: '/privacy',
            name: 'privacy',
            meta:{title:'隐私政策',index:8},
            component: privacy
        },
        {
            path: '/faceRecognition',
            name: 'faceRecognition',
            meta:{title:'人脸信息授权协议',index:9},
            component: faceRecognition
        },
        
    ]
})

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this,location).catch(err => err)
}

router.beforeEach((to,form,next) => {
    if(to.meta.title){
        document.title = to.meta.title //动态设置标签页title
    }
    if(to.matched.some(record => record.meta.requiresAuth)){
        console.log(localStorage.userId)
        if(!localStorage.userId){
            next({
                path:'/',
                query:{redirect:to.fullpath}
            })
        }else {
            next();
        }
    }else {
        next();
    }
//     console.log('开始页面切换');
//     console.log(to.fullPath)
//     var tempId = Utils.getCookie('temp-id');
//     var userInfo = sessionStorage.getItem('ssm_u_info');
//     if(to.fullPath != '/' && (tempId == null || tempId == '' || userInfo == null || userInfo == '')){
//       window.location.href = '/';
//     }
   
  });

export default router