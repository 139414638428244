<template>
  <div class="page">
    <PageTitle title="访客信息自助录入" />
    <div class="page_content">
      <div class="page_content_portrait">
        <van-uploader
          v-model="fileList"
          :after-read="onRead"
          :max-size="10240 * 1024"
          @oversize="onOversize"
          :max-count="1"
        >
          <div class="my_uploader">
            <van-icon class="iconfont" class-prefix="icon" name="paizhao" />
            <div class="my_uploader_text">点击上传头像可扫脸通行</div>
          </div>
        </van-uploader>
      </div>

      <div class="basic_info">
        <van-form @submit="onSubmit" :show-error-message="false">
          <van-cell-group>
            <van-field
              v-model="submitValue.username"
              name="realName"
              label="姓名"
              required
              placeholder="请输入姓名"
              :rules="[{ required: true, message: '请输入姓名' }]"
            />
            <van-field
              v-model="submitValue.phone"
              type="tel"
              name="phone"
              label="手机号"
              required
              placeholder="请输入手机号"
              :rules="[{ required: true, message: '请输入手机号' }]"
            />
            <van-field
              readonly
              clickable
              required
              name="picker"
              :value="idCardTypeName"
              label="证件类型"
              placeholder="请选择证件类型"
              @click="showPicker = true"
              :rules="[{ required: true, message: '请选择证件类型' }]"
            />
            <van-popup
              v-model="showPicker"
              get-container="#app"
              safe-area-inset-bottom
              position="bottom"
              :style="{
                height: '30%',
                white: '100%',
              }"
            >
              <van-picker
                item-height="1.25rem"
                show-toolbar
                visible-item-count="3"
                :columns="columns"
                @confirm="onConfirm"
                @cancel="showPicker = false"
              />
            </van-popup>
            <div v-if="idCardType === '0'">
              <van-field
                label="证件号"
                v-model="submitValue.idCard"
                readonly
                required
                clickable
                placeholder="请输入证件号"
                @touchstart.stop="show = true"
                :rules="[{ pattern, message: '请输入证件号' }]"
                name="idCard"
              />
              <van-number-keyboard
                v-model="submitValue.idCard"
                :show="show"
                extra-key="X"
                close-button-text="完成"
                @blur="show = false"
              />
            </div>

            <van-field
              v-else
              v-model="submitValue.idCard"
              name="idCard"
              label="证件号"
              required
              placeholder="请输入证件号"
              :rules="[{ required: true, message: '请输入证件号' }]"
            />
            <van-field
              readonly
              clickable
              required
              name="sex"
              :value="sexName"
              label="性别"
              placeholder="请选择性别"
              @click="showPickerSex = true"
              :rules="[{ required: true, message: '请选择性别' }]"
            />
            <van-popup
              v-model="showPickerSex"
              get-container="#app"
              safe-area-inset-bottom
              position="bottom"
              :style="{
                height: '30%',
                white: '100%',
              }"
            >
              <van-picker
                item-height="1.25rem"
                show-toolbar
                visible-item-count="3"
                :columns="sex"
                value-key="text"
                @confirm="onConfirmSex"
                @cancel="showPickerSex = false"
              />
            </van-popup>
          </van-cell-group>
          <van-cell-group title="访问信息">
            <van-field
              readonly
              clickable
              required
              name="visitUserId"
              :value="submitValue.surveyed"
              label="受访人"
              placeholder="请选择受访人"
              @click="showPickerSurveyed = true"
              :rules="[{ required: true, message: '请选择受访人' }]"
            />
            <van-field
              readonly
              clickable
              required
              name="startTime"
              :value="submitValue.createTime"
              label="开始时间"
              placeholder="点击选择开始时间"
              @click="showPickerCreactTime = true"
              :rules="[{ required: true, message: '请选择开始时间' }]"
            />
            <van-popup
              v-model="showPickerCreactTime"
              :style="{
                height: '30%',
                white: '100%',
              }"
              position="bottom"
            >
              <van-datetime-picker
                item-height="1.25rem"
                type="datetime"
                visible-item-count="3"
                :min-date="minDate"
                :max-date="maxDate"
                @confirm="onConfirmCreact"
                @cancel="showPickerCreactTime = false"
              />
            </van-popup>

            <van-field
              readonly
              clickable
              required
              name="endTime"
              :value="submitValue.endTime"
              label="结束时间"
              placeholder="点击选择结束时间"
              @click="showPickerEndTime = true"
              :rules="[{ required: true, message: '请选择结束时间' }]"
            />
            <van-popup
              item-height="1.25rem"
              v-model="showPickerEndTime"
              :style="{
                height: '30%',
                white: '100%',
              }"
              position="bottom"
            >
              <van-datetime-picker
                item-height="1.25rem"
                type="datetime"
                visible-item-count="3"
                :min-date="minDate"
                :max-date="maxDate"
                @confirm="onConfirmEnd"
                @cancel="showPickerEndTime = false"
              />
            </van-popup>
          </van-cell-group>

          <div class="isAgreementStyle">
            <van-checkbox v-model="isAgreement">
              <span>
                请阅读并同意
                <a style="color: #2a82e4" @click="toPrivacy">《隐私政策》</a>
                <a style="color: #2a82e4" @click="toFaceRecognition"
                  >《人脸信息授权协议》</a
                >
              </span>
            </van-checkbox>
          </div>

          <div style="padding: 0.5rem">
            <van-button
              :disabled="!isAgreement"
              block
              type="info"
              class="submitStyle"
              native-type="submit"
            >
              提交审核
            </van-button>
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>
import { isWeiXin, timeFormatter } from "../../Utils/utils";
import PageTitle from "../../components/pageTitle";
import { upload, visitorsAutoAdd, refreshUser } from "../../api/api";
import { uploadFile, POST_Body, GET_Query } from "../../Utils/request";
import { Notify, Dialog } from "vant";
// import {init} from '../../assets/js/weixinSDK'
export default {
  name: "visitorsSelfHelp",
  props: {
    msg: String,
  },
  components: {
    PageTitle,
  },
  created() {
    let userId = this.$route.query.userId;
    this.userId = userId;
    this.isWeiXinWeb = isWeiXin();
    GET_Query(refreshUser, { userId: userId }).then((res) => {
      if (res.code === 200) {
        if (res.result.realName) {
          this.submitValue.surveyed = res.result.realName;
        }
      } else {
        Notify({ type: "danger", message: res.message });
      }
    });
    //  init({
    //     APIs: ['onMenuShareTimeline', 'onMenuShareAppMessage'],
    //     callback: {
    //       'title': '微信分享',
    //       'imgUrl': this.imgUrl,
    //       'description': '分享',
    //       'url': origin + '/#/produtList?isShare=1&openId=' + 1,
    //       successMethod: ''
    //     }
    //   })
  },
  data() {
    return {
      // imgUrl: "../../assets/logo.png",
      show: false,
      isWeiXinWeb: "",
      isAgreement: false,
      idCardType: "0",
      idCardTypeName: "身份证",
      sexType: "1",
      sexName: "男",
      pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
      columns: [
        { id: "0", text: "身份证" },
        { id: "1", text: "护照" },
      ],
      sex: [
        { id: "1", text: "男" },
        { id: "2", text: "女" },
      ],
      showPicker: false,
      showPickerSex: false,
      showPickerCreactTime: false,
      showPickerEndTime: false,
      fileList: [],
      submitValue: {
        idCardType: "",
        username: "",
        phone: "",
        surveyed: "",
        createTime: "",
        endTime: "",
      },
      userId: "",
      minDate: new Date("2021/12/1"),
      maxDate: new Date("2100/12/31"),
      currentDate: new Date(),
    };
  },
  methods: {
    onSubmit(values) {
      const submitValue = {
        ...values,
        photo: this.submitValue.photo,
        idCardType: this.idCardType,
        sex: this.sexType,
        visitUserId: this.userId,
      };
      if (!this.submitValue.photo) {
        Notify({ type: "danger", message: "请上传头像后再试！" });
        return false;
      }
      POST_Body(visitorsAutoAdd, { ...submitValue }).then((res) => {
        if (res.code === 200 && res.success === true) {
          Dialog.alert({
            title: "温馨提示",
            message: res.message,
          }).then(() => {
            this.submitValue = {
              idCardType: "",
              username: "",
              phone: "",
              surveyed: this.submitValue.surveyed,
              createTime: "",
              endTime: "",
            };
            this.fileList = [];
          });
        } else {
          Notify({ type: "danger", message: res.message });
        }
      });
    },
    onConfirm(value) {
      this.idCardType = value.id;
      this.idCardTypeName = value.text;
      this.showPicker = false;
    },
    onConfirmSex(value) {
      this.sexType = value.id;
      this.sexName = value.text;
      this.showPickerSex = false;
    },
    onConfirmCreact(val) {
      this.submitValue.createTime = timeFormatter(val);
      this.showPickerCreactTime = false;
    },
    onConfirmEnd(value) {
      this.submitValue.endTime = timeFormatter(value);
      this.showPickerEndTime = false;
    },
    onRead(file) {
      // 上传图片到图片服务器
      uploadFile(upload, file).then((res) => {
        if (res.success) {
          this.submitValue.photo = res.message;
        } else {
          Notify({ type: "danger", message: res.message });
        }
      });
    },
    onOversize(file) {
      console.log(file);
      Notify({ type: "danger", message: "图像大小不能超过10M" });
    },
    toPrivacy() {
      this.$router.push({ path: "/privacy" });
    },
    toFaceRecognition() {
      this.$router.push({ path: "/faceRecognition" });
    },
  },
};
</script>

<style scoped>
.iconfont {
  font-size: 1.25rem;
  color: rgb(221, 219, 219);
  display: flex;
  justify-content: center;
}
.page {
  min-height: 100%;
  background-color: rgb(236, 234, 234);
}
.page_content_portrait {
  width: 100%;
  height: 3.75rem;
  background-color: #fff;
  margin-bottom: 0.5rem;
}
.van-uploader,
.van-uploader__input-wrapper,
.my_uploader {
  width: 100%;
  height: 3.75rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.van-uploader {
  display: flex;
  justify-content: center;
}
.van-uploader__input {
  padding: 50%;
}
.my_uploader_text {
  font-size: 0.5rem;
  color: rgb(221, 219, 219);
  width: 100%;
}
.van-cell-group {
  margin: 0;
}
.isAgreementStyle {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
  font-size: 25px;
}
.submitStyle {
  border-radius: 0.3125rem;
}
</style>
