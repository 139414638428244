<template>
  <div>
    <PageTitle title="防疫卫士" />

    <div class="login_page_content">
      <div class="login_logo">
        <van-image width="3.125rem" height="3.125rem" :src="logimg" />
      </div>
    </div>

    <div>
      <van-form @submit="onSubmit" :show-error-message="false">
        <van-field
          v-model="phone"
          type="tel"
          name="mobile"
          label="手机号"
          clearable
          placeholder="请输入手机号"
          :rules="[{ required: true, message: '请正确填写手机号' }]"
        />
        <van-field
          v-model="code"
          name="code"
          center
          clearable
          type="digit"
          label="短信验证码"
          placeholder="请输入短信验证码"
          :rules="[{ required: true, message: '请正确填写手机号' }]"
        >
          <template #button>
            <van-button
              :disabled="sendCodeLoading"
              size="small"
              @click="getMsmCode"
              type="primary"
              native-type="button"
              >{{ sendCodeText }}</van-button
            >
          </template>
        </van-field>
        <div style="margin: 0.5rem">
          <van-button size="large" block type="info" native-type="submit"
            >登录</van-button
          >
        </div>
        <div class="treaty">
          点击登录，即表示已阅读并同意<a
            style="color: #2a82e4"
            @click="readRegister"
            >《注册会员服务条款》</a
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Notify } from "vant";
import { isWeiXin } from "../../Utils/utils";
import PageTitle from "../../components/pageTitle";
import { login } from "../../api/api";
import { POST_Query } from "../../Utils/request";
export default {
  name: "Login",
  props: {
    msg: String,
  },
  components: {
    PageTitle,
  },
  data() {
    return {
      isWeiXinWeb: "",
      phone: "",
      code: "",
      sendCodeText: "发送验证码",
      sendCodeLoading: true,
      logimg: require("../../assets/logo.png"),
    };
  },
  created() {
    this.isWeiXinWeb = isWeiXin();
  },
  watch: {
    phone(newValue) {
      let re = /^1\d{10}$/;
      if (newValue && re.test(newValue)) {
        this.sendCodeLoading = false;
      } else {
        this.sendCodeLoading = true;
      }
    },
  },
  methods: {
    getMsmCode() {
      if (!/^1\d{10}$/.test(this.phone)) {
        this.sendCodeLoading = true;
        console.log(this);
        Notify({ type: "danger", message: "手机号码输入有误" });
      } else {
        this.sendCodeLoading = true;
        let i = 60; //倒计时时间
        let timer = setInterval(() => {
          this.sendCodeText = i + " 秒";
          i--;
          if (i < 0) {
            this.sendCodeLoading = false;
            this.sendCodeText = "发送验证码";
            clearInterval(timer);
          }
        }, 1000);
      }
    },
    onSubmit(values) {
      console.log("submit", values);
      POST_Query(login, values).then((res) => {
        if (res.code === 200) {
          localStorage.setItem("userId", res.result.id);
          localStorage.setItem("userName", res.result.realName);
          localStorage.setItem("userMobile", res.result.mobile);
          this.$router.replace({ path: "home" });
        } else {
          Notify({ type: "danger", message: res.message });
        }
      });
    },
    readRegister() {
      this.$router.push({ path: "/register" });
    },
  },
};
</script>

<style scoped>
html,
body {
  min-height: 100%;
  background-color: white !important ;
}
.login_page_content {
  margin-top: 16px;
}
.login_logo {
  text-align: center;
  margin-bottom: 16px;
}
.treaty {
  text-align: center;
  font-size: 25px;
}
</style>
