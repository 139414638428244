var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('PageTitle',{attrs:{"title":"访客信息自助录入"}}),_c('div',{staticClass:"page_content"},[_c('div',{staticClass:"page_content_portrait"},[_c('van-uploader',{attrs:{"after-read":_vm.onRead,"max-size":10240 * 1024,"max-count":1},on:{"oversize":_vm.onOversize},model:{value:(_vm.fileList),callback:function ($$v) {_vm.fileList=$$v},expression:"fileList"}},[_c('div',{staticClass:"my_uploader"},[_c('van-icon',{staticClass:"iconfont",attrs:{"class-prefix":"icon","name":"paizhao"}}),_c('div',{staticClass:"my_uploader_text"},[_vm._v("点击上传头像可扫脸通行")])],1)])],1),_c('div',{staticClass:"basic_info"},[_c('van-form',{attrs:{"show-error-message":false},on:{"submit":_vm.onSubmit}},[_c('van-cell-group',[_c('van-field',{attrs:{"name":"realName","label":"姓名","required":"","placeholder":"请输入姓名","rules":[{ required: true, message: '请输入姓名' }]},model:{value:(_vm.submitValue.username),callback:function ($$v) {_vm.$set(_vm.submitValue, "username", $$v)},expression:"submitValue.username"}}),_c('van-field',{attrs:{"type":"tel","name":"phone","label":"手机号","required":"","placeholder":"请输入手机号","rules":[{ required: true, message: '请输入手机号' }]},model:{value:(_vm.submitValue.phone),callback:function ($$v) {_vm.$set(_vm.submitValue, "phone", $$v)},expression:"submitValue.phone"}}),_c('van-field',{attrs:{"readonly":"","clickable":"","required":"","name":"picker","value":_vm.idCardTypeName,"label":"证件类型","placeholder":"请选择证件类型","rules":[{ required: true, message: '请选择证件类型' }]},on:{"click":function($event){_vm.showPicker = true}}}),_c('van-popup',{style:({
              height: '30%',
              white: '100%',
            }),attrs:{"get-container":"#app","safe-area-inset-bottom":"","position":"bottom"},model:{value:(_vm.showPicker),callback:function ($$v) {_vm.showPicker=$$v},expression:"showPicker"}},[_c('van-picker',{attrs:{"item-height":"1.25rem","show-toolbar":"","visible-item-count":"3","columns":_vm.columns},on:{"confirm":_vm.onConfirm,"cancel":function($event){_vm.showPicker = false}}})],1),(_vm.idCardType === '0')?_c('div',[_c('van-field',{attrs:{"label":"证件号","readonly":"","required":"","clickable":"","placeholder":"请输入证件号","rules":[{ pattern: _vm.pattern, message: '请输入证件号' }],"name":"idCard"},on:{"touchstart":function($event){$event.stopPropagation();_vm.show = true}},model:{value:(_vm.submitValue.idCard),callback:function ($$v) {_vm.$set(_vm.submitValue, "idCard", $$v)},expression:"submitValue.idCard"}}),_c('van-number-keyboard',{attrs:{"show":_vm.show,"extra-key":"X","close-button-text":"完成"},on:{"blur":function($event){_vm.show = false}},model:{value:(_vm.submitValue.idCard),callback:function ($$v) {_vm.$set(_vm.submitValue, "idCard", $$v)},expression:"submitValue.idCard"}})],1):_c('van-field',{attrs:{"name":"idCard","label":"证件号","required":"","placeholder":"请输入证件号","rules":[{ required: true, message: '请输入证件号' }]},model:{value:(_vm.submitValue.idCard),callback:function ($$v) {_vm.$set(_vm.submitValue, "idCard", $$v)},expression:"submitValue.idCard"}}),_c('van-field',{attrs:{"readonly":"","clickable":"","required":"","name":"sex","value":_vm.sexName,"label":"性别","placeholder":"请选择性别","rules":[{ required: true, message: '请选择性别' }]},on:{"click":function($event){_vm.showPickerSex = true}}}),_c('van-popup',{style:({
              height: '30%',
              white: '100%',
            }),attrs:{"get-container":"#app","safe-area-inset-bottom":"","position":"bottom"},model:{value:(_vm.showPickerSex),callback:function ($$v) {_vm.showPickerSex=$$v},expression:"showPickerSex"}},[_c('van-picker',{attrs:{"item-height":"1.25rem","show-toolbar":"","visible-item-count":"3","columns":_vm.sex,"value-key":"text"},on:{"confirm":_vm.onConfirmSex,"cancel":function($event){_vm.showPickerSex = false}}})],1)],1),_c('van-cell-group',{attrs:{"title":"访问信息"}},[_c('van-field',{attrs:{"readonly":"","clickable":"","required":"","name":"visitUserId","value":_vm.submitValue.surveyed,"label":"受访人","placeholder":"请选择受访人","rules":[{ required: true, message: '请选择受访人' }]},on:{"click":function($event){_vm.showPickerSurveyed = true}}}),_c('van-field',{attrs:{"readonly":"","clickable":"","required":"","name":"startTime","value":_vm.submitValue.createTime,"label":"开始时间","placeholder":"点击选择开始时间","rules":[{ required: true, message: '请选择开始时间' }]},on:{"click":function($event){_vm.showPickerCreactTime = true}}}),_c('van-popup',{style:({
              height: '30%',
              white: '100%',
            }),attrs:{"position":"bottom"},model:{value:(_vm.showPickerCreactTime),callback:function ($$v) {_vm.showPickerCreactTime=$$v},expression:"showPickerCreactTime"}},[_c('van-datetime-picker',{attrs:{"item-height":"1.25rem","type":"datetime","visible-item-count":"3","min-date":_vm.minDate,"max-date":_vm.maxDate},on:{"confirm":_vm.onConfirmCreact,"cancel":function($event){_vm.showPickerCreactTime = false}}})],1),_c('van-field',{attrs:{"readonly":"","clickable":"","required":"","name":"endTime","value":_vm.submitValue.endTime,"label":"结束时间","placeholder":"点击选择结束时间","rules":[{ required: true, message: '请选择结束时间' }]},on:{"click":function($event){_vm.showPickerEndTime = true}}}),_c('van-popup',{style:({
              height: '30%',
              white: '100%',
            }),attrs:{"item-height":"1.25rem","position":"bottom"},model:{value:(_vm.showPickerEndTime),callback:function ($$v) {_vm.showPickerEndTime=$$v},expression:"showPickerEndTime"}},[_c('van-datetime-picker',{attrs:{"item-height":"1.25rem","type":"datetime","visible-item-count":"3","min-date":_vm.minDate,"max-date":_vm.maxDate},on:{"confirm":_vm.onConfirmEnd,"cancel":function($event){_vm.showPickerEndTime = false}}})],1)],1),_c('div',{staticClass:"isAgreementStyle"},[_c('van-checkbox',{model:{value:(_vm.isAgreement),callback:function ($$v) {_vm.isAgreement=$$v},expression:"isAgreement"}},[_c('span',[_vm._v(" 请阅读并同意 "),_c('a',{staticStyle:{"color":"#2a82e4"},on:{"click":_vm.toPrivacy}},[_vm._v("《隐私政策》")]),_c('a',{staticStyle:{"color":"#2a82e4"},on:{"click":_vm.toFaceRecognition}},[_vm._v("《人脸信息授权协议》")])])])],1),_c('div',{staticStyle:{"padding":"0.5rem"}},[_c('van-button',{staticClass:"submitStyle",attrs:{"disabled":!_vm.isAgreement,"block":"","type":"info","native-type":"submit"}},[_vm._v(" 提交审核 ")])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }